import React from "react";

const educationContent = [
  {
    year: "2018",
    degree: "Highschool",
    institute: "Parkville Highschool Center For Mathmatics, Science and Computer Science ",
    details: `Cisco Networking Academy Magent Program Parkville HS Magnet Program`,
  },
  {
    year: "2018-2019",
    degree: "Researcher",
    institute: "Univeristy of Maryland, College Park",
    details: `Participating in the FIRE (First-Year Innovation & Research Experience) program at the University of Maryland (UMD) is a dynamic initiative designed to provide first-year students with hands-on research and innovation opportunities. Through the FIRE program, students have the chance to engage in interdisciplinary projects across various fields.`,
  },
  {
    year: "2019-2020",
    degree: "Researcher Mentor",
    institute: "Univeristy of Maryland, College Park",
    details: `A FIRE research mentors are an experienced and knowledgeable individual who guides and supports researchers in their academic or scientific pursuits. We play a crucial role in providing guidance, expertise, and mentorship to help researchers develop their skills and navigate the research process.`,
  },
  {
    year: "2018 - Present",
    degree: "Bachelor of Arts in Technology and information Design (InfoDesign)",
    institute: "Univeristy of Maryland, College Park",
    details: `The infoDesign program at UMD teaches students to solve complex problems at the intersection of people and information. It emphasizes creative design, technolog application, and iterative development. The program fosters a start-up mentality and hands on learning through studio classes and labs. It prepares student for rapid prototyping and effective communication across disciplines.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
