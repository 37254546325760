import React from "react";

const ModalFiveContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>FBLA</h3>
          <div className="row open-sans-font">
          <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Competition </span>:{" "}
              <span className="ft-wt-600 uppercase">Future Business Leader of America</span>
              <br></br>
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Category </span>:{" "}
              <span className="ft-wt-600 uppercase">Computer Problem Solving</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">5th place in Computer Problem Solving (2017) State Level</span>
              <br></br>
              <span className="ft-wt-600 uppercase">1st place in Computer Problem Solving (2017) Regional Level</span>
              <br></br>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase"> </span>
              Personal computer components,
              Laptop and portable devices, 
              Operating Systems, 
              Printers and scanners, 
              Networks,Security
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://docs.google.com/document/d/1o9d4u-YpIewrWNrgYaVYxH02wDj5VsCzSzkoORdshaQ/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Practice Test
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}


      </figure>
    </div>
  
  );
};

export default ModalFiveContent;
