import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>CyberPatriot</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">CyberPatriot’s National Youth Cyber Defense Competition challenges teams of high school and middle school students to find and fix cybersecurity vulnerabilities in virtual operating systems.</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <br></br>
                3rd place in the CyberPatriot VIII Open Divion Silver Tier State Round
                <br></br>
                5th Place in the CyberPatriot IX Open Division Gold Tier State Round
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <br></br>
                <ul>
                <li>Maintaining and hardening critical services</li>
                <li>Fixing vulnerabilities</li>
                <li>Removing malware from the systems</li>
                <li>Answering forensics questions</li>
                <li>Windows 10</li> 
                <li>Windows Server 2016, and Windows Server 2019</li>
                <li>Ubuntu 16, Ubuntu 18, Debian 9</li>
                <li>Virtual Network Configuration</li> 
                <li>Subnetting and IP Addressing</li> 
                <li>Wireless Routing</li>
                </ul>
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.uscyberpatriot.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalEightContent;
