import React from "react";
import Slider from "react-slick";

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };



const ModalTwentytwoContent = () => {
  return (
    <div className="slideshow">
      <figure>
        <figcaption>
          <h3>INST380: Changemaker Challenge</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary The Changemaker Challenge is a semester-long project to learn about, engage with, and take action for a social or environmental issue of your choosing.</span>:{" "}
              <span className="ft-wt-600 uppercase"></span>

            </div>
            {/* End .col */}

            {/* <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Certificate </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <ul>
                    <li>Google Kubernetes Engine Best Practices</li>
                    <li>Application Development(Java, Python)</li>
                    <li>Cloud Architecture</li>
                    <li>Google Cloud Essentials</li>
                    <li>And Many More</li>
                </ul>
              </span>
            </div> */}

            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.youtube.com/playlist?list=PLtS_Kkb0ZRKYS72P03oSSc42Vsw5k9FUa"
                  target="_blank"
                  rel="noreferrer"
                >
                  Playlist
                </a>
              </span>
            </div>
            {/* End .col */}


          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}


        <Slider {...settings}>
          <div className="slide_item">
               <div className="videocontainer">
                <video
                  id="video"
                  className="responsive-video"
                  controls
                  poster="img/projects/Project-21/challengebg.jpg"
                >
                  <source src="img/projects/Project-21/challenge1.mp4" />
                </video>
              </div>
          </div>
          {/* <div className="slide_item">
            <img src="img/projects/Project-9/certificate-2.jpg" alt="Certificate 2" />
          </div> */}
        </Slider>

        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalTwentytwoContent;
