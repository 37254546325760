import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>VolgaCTF</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              It is an international contest that gathers more than 1000 teams from all around the globe.Competition finals are held in Samara city and are hosted by Samara University in cooperation with IT and Communications Department of Samara Region
              <span className="ft-wt-600 uppercase"></span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              <span className="ft-wt-600 uppercase"></span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase"></span>
              <ul>
                  <li>Cryptography</li>
                  <li>Forensics</li>
                  <li>Web Security</li>
                  <li>Exploitation (Binary, networking, memory corruption, software, etc)</li>
                  <li>Reverse Engineering</li>
                  <li>Information gathering</li>
                </ul>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://volgactf.ru/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalEightContent;
