import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>MagicCTF</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">The Mid-Atlantic Gigabit Innovation Collaboratory (MAGIC) sponsors student programming to enhance workforce development for technology companies, particularly in the cybersecurity industry. Unlike many competitions out there, MAGIC events are positioned as entry-level beginner competitions to appeal to the widest possible population of students. High school, college, and nontraditional college students interested in pursuing a career in cybersecurity are invited to participate.</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              <span className="ft-wt-600 uppercase">Top 5 (2019)</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase"> Programming
                <ul>
                  <li>Cryptography</li>
                  <li>Forensics</li>
                  <li>Web Security</li>
                  <li>Exploitation (Binary, networking, memory corruption, software, etc)</li>
                  <li>Reverse Engineering</li>
                  <li>Information gathering</li>
                </ul>
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://magicinc.org/programs/cybersecurity/capture-the-flag"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalEightContent;
