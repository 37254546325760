import React from "react";

const ModalFourContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>FBLA</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Competition </span>:{" "}
              <span className="ft-wt-600 uppercase">Future Business Leader of America</span>
              <br></br>
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Category </span>:{" "}
              <span className="ft-wt-600 uppercase">Network Design</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">1st place in Network Design (2016) State Level</span>
              <br></br>
              <span className="ft-wt-600 uppercase">1st place in Network Design (2016) Regional Level</span>
              <br></br>
              <span className="ft-wt-600 uppercase">1st place in Network Design (2018) State Level</span>
              <br></br>
              <span className="ft-wt-600 uppercase">2nd place in Network Design (2018) Regional Level</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase"> </span>
              Network installation—planning and configuration 
              problem solving and troubleshooting 
              network administrator functions 
              configuration of Internet resources 
              backup and disaster recovery 
              configuration of network resources and services

            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://docs.google.com/document/d/1Z0IECCRadU7Cp26hba-VAhIpM7qGGYbz/edit?usp=sharing&ouid=115025229888529871289&rtpof=true&sd=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Senario
                </a>
              </span>
              <br></br>
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://drive.google.com/file/d/11YjSSJE57BkLnsUQH6iPs7ba5UBPxD1S/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Performance rubric
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}

        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalFourContent;
