import React from "react";

const ModalSixContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>SAE Cyber Auto Challenge</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summay </span>:{" "}
              <span className="ft-wt-600 uppercase">An intense 5 days training in Detriot, Michigan. Where students from both highschool and university come together to learn about the today's automotive industry directly from the best cyber researchers, hackers, automotive engineers, and academia partnered with industry in order to advance the next generation of cyber security specialists. After training there was 24 hours hackathon where participent shows off their skills to the automotive industry. </span>
            </div>
            {/* End .col */}


            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase"><br></br>Automotive Cyber Security<br></br>CAN Protocols<br></br>Programming</span>
            </div>
            {/* End .col */}
            
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Host </span>:{" "}
              <span className="ft-wt-600 uppercase">SAE International</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.sae.org/attend/cyberauto"
                  target="_blank"
                  rel="noreferrer"
                >
                  SAE Cyberauto
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
  );
};

export default ModalSixContent;
