import React from "react";

const experienceContent = [
  {
    year: "2016 - Present",
    position: "Manager",
    compnayName: "Oh Delious inc.",
    details: `My responsible as Manager is to overseeing the overall operations and ensuring the smooth functioning of a restaurant. Also, In charge of various aspects, including staff management, customer service, inventory control, and financial management. I ensures that the restaurant runs efficiently, meets quality standards, and delivers an exceptional dining experience. They coordinate with the kitchen staff, front-of-house team, and other departments to maintain a positive work environment and uphold the restaurant's reputation.
  `},
  {
    year: "2016 - Present",
    position: "Techsupport",
    compnayName: "Oh Delious inc.",
    details: `I responsible for handling technical issues related to the restaurant's computer systems, software, and other technology infrastructure. I troubleshoot and resolve technical problems that may arise with point-of-sale (POS) systems, online ordering platforms, reservation systems, network connections, or other technology used in the restaurant.
  `},
  {
    year: "2016 - 2018",
    position: "President / Vice President",
    compnayName: "Cyber security club at Parkville highschool",
    details: `The position of President at our Cyber Security Club is a highly influential and crucial role in driving the club's mission and activities. As President, you will lead and inspire a community of cybersecurity enthusiasts, guiding them towards achieving excellence in the field.
  `},
  {
    year: "2017 - 2018",
    position: "Secretary / Regional Representative",
    compnayName: "FBLA",
    details: `I plays a vital role in ensuring smooth administrative operations and representing the club's interests at a regional level. We are responsible for maintaining accurate records, taking meeting minutes, and organizing club-related documentation. The Secretary/Regional Representative also acts as a liaison between the club and external organizations, representing the club's interests and communicating important information to and from the regional level.
    `},
    {
      year: "2021 - Present",
      position: "Founder",
      compnayName: "BBmonstah",
      details: `I was one of the founder of a blockchain startup company. We are visionary entrepreneur passionate about revolutionizing the education industry through the power of blockchain technology. They possess deep expertise in both education and blockchain, understanding the unique challenges and opportunities in the field. We collaborates with educators, technologists, and industry experts to develop cutting-edge educational tools and services that empower learners and foster lifelong learning. Their mission-driven approach and commitment to educational innovation drive the company's growth and impact in the ever-evolving landscape of education.`
    },

 
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
