import React from "react";
import Slider from "react-slick";

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };



const ModalNineContent = () => {
  return (
    <div className="slideshow">
      <figure>
        <figcaption>
          <h3>Qwiklabs</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <span className="ft-wt-600 uppercase">Qwiklabs provides lab learning environments that help developers and IT professionals get hands-on experience working with leading cloud platforms and software.</span>

            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Certificate </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <ul>
                    <li>Google Kubernetes Engine Best Practices</li>
                    <li>Application Development(Java, Python)</li>
                    <li>Cloud Architecture</li>
                    <li>Google Cloud Essentials</li>
                    <li>And Many More</li>
                </ul>
              </span>
            </div>

            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.qwiklabs.com/public_profiles/462531b3-b2b9-4fd2-bd95-13fb9005aca6"
                  target="_blank"
                  rel="noreferrer"
                >
                  Profile
                </a>
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.qwiklabs.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.qwiklabs.com
                </a>
              </span>
              
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}


        <Slider {...settings}>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-1.jpg" alt="Certificate 1" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-2.jpg" alt="Certificate 2" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-3.jpg" alt="Certificate 3" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-4.jpg" alt="Certificate 4" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-5.jpg" alt="Certificate 5" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-6.jpg" alt="Certificate 6" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-7.jpg" alt="Certificate 7" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-8.jpg" alt="Certificate 8" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-9.jpg" alt="Certificate 9" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-10.jpg" alt="Certificate 10" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-11.jpg" alt="Certificate 11" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-12.jpg" alt="Certificate 12" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-13.jpg" alt="Certificate 13" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-14.jpg" alt="Certificate 14" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-15.jpg" alt="Certificate 15" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-16.jpg" alt="Certificate 16" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-17.jpg" alt="Certificate 17" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-18.jpg" alt="Certificate 18" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-19.jpg" alt="Certificate 19" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-20.jpg" alt="Certificate 20" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-21.jpg" alt="Certificate 21" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-22.jpg" alt="Certificate 22" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-23.jpg" alt="Certificate 23" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-24.jpg" alt="Certificate 24" />
          </div>
          <div className="slide_item">
            <img src="img/projects/Project-9/certificate-25.jpg" alt="Certificate 25" />
          </div>
        </Slider>

        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalNineContent;
