import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>HackUMBC</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">
                The University of Maryland, Baltimore County’s 24-hour tech innovation marathon where students across the East Coast collaborate on new ideas to build mobile, web and hardware projects. HackUMBC invites diverse groups of students to enjoy a weekend of hacking, workshops, tech talks, networking, and other fun activities. At the end of 24 hours, participants’ projects are presented and judged for different prize categories from sponsors and other organizations.
                </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Participate </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">
                Fall HackUMBC 2018
                <br></br>
                Fall HackUMBC 2017
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills used</span>:{" "}
              <span className="ft-wt-600 uppercase">
                <ul>
                  <li>
                    AWS service
                    </li>
                  <li>
                    HTML
                    </li>
                  <li>
                  CSS
                    </li>
                  <li>
                    Javascript
                    </li>
                  <li>
                    Soldering  
                    </li>
                  <li>
                    Protrotyping Electronic Device  
                    </li>
                  <li>
                    Raspberry pi   
                    </li>
                  <li>
                    Linux
                  </li>
                </ul>
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://hackumbc.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}
      </figure>
    </div>
  );
};

export default ModalEightContent;
