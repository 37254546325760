import React from "react";

const ModalSevenContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>National Cyber League </h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">
                An online capture the flag style Competition, where the nine main topics are the critical cybersecurity workforce. 
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://nationalcyberleague.org/about"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>

            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase">
              <ul>
                  <li>Open Source Intelligent</li>  
                  <li>Cryptography</li>  
                  <li>Password Cracking</li>  
                  <li>Log Analysis</li>  
                  <li>Network Traffic Analysis</li>  
                  <li>Forensics</li>  
                  <li>Web App Exploitation</li>  
                  <li>Scanning</li>
                  <li>Enumeration & Exploitation</li>
                </ul>
              </span>
            </div>
            {/* End .col */}



            
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}<br></br>
              <span className="ft-wt-600 uppercase">
                <ul>
                  <li>2017 Team competition top 60% worldwide (Team leader) <br></br></li>
                  <li>2017 Team competition 2nd place Bracket (Team leader) <br></br></li>
                  <li>2017 individual competition top 2000 <br></br></li>
                  <li>2017 individual bronze bracket top 1000 <br></br></li>
                  <li>2018 Spring top 300 individual <br></br></li>
                  <li>2018 Spring 12th bronze bracket<br></br></li>
                  <li>2018 Fall 50th silver bracket<br></br></li>
                  <li>2018 Fall top 250 individual<br></br></li>
                  <li>2019 - 2020 Distric advisor<br></br></li>
                </ul>
                
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
  );
};

export default ModalSevenContent;
