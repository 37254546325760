import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>AT&T Summer Learning Academy</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">The AT&T Summer Learning Academy is a free self-paced online learning certificate program
powered by award winning AT&T University curriculum</span>
            </div>
            {/* End .col */}

          

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <br></br>
                <ul>
                  <li>Artificial Intelligence</li>
                  <li>Cable Routing Basics</li>
                  <li>Cybersecurity White Pages</li>
                  <li>Design Thinking</li>
                  <li>Internet of things</li>
                  <li>Machine Learning 101</li>
                  <li>Phone & Ethernet Wiring</li>
                  <li>Power of Communication</li>
                  <li>Project Management</li>
                  <li>and many more</li>
                </ul>


              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://inside.att.jobs/externship"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

       
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalEightContent;
