import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Hack the box</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">Hack The Box is a massive, online cybersecurity training platform, allowing individuals, companies, universities and all kinds of organizations around the world to level up their hacking skills.</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              
              <span className="ft-wt-600 uppercase">
                <br></br>
                Peak rank top 400 in the world</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase">
              <ul>
                <li>Perform tests on applications, network devices, and cloud infrastructures</li>
                <li>Design and conduct simulated social engineering attacks</li>
                <li>Research and experiment with different types of attacks</li>
                <li>Review code for security vulnerabilities</li>
                <li>Reverse engineer malware or spam</li>
              </ul>
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://app.hackthebox.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalEightContent;
