import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import ModalOneContent from "./modal/ModalOneContent";
import ModalTwoContent from "./modal/ModalTwoContent";
import ModalThreeContent from "./modal/ModalThreeContent";
import ModalFourContent from "./modal/ModalFourContent";
import ModalFiveContent from "./modal/ModalFiveContent";
import ModalSixContent from "./modal/ModalSixContent";
import ModalSevenContent from "./modal/ModalSevenContent";
import ModalEightContent from "./modal/ModalEightContent";
import ModalNineContent from "./modal/ModalNineContent";
import ModalTenContent from "./modal/ModalTenContent";
import ModalElevenContent from "./modal/ModalElevenContent";
import ModalTwelveContent from "./modal/ModalTwelveContent";
import ModalThirteenContent from "./modal/ModalThirteenContent";
import ModalFourteenContent from "./modal/ModalFourteenContent";
import ModalFifteenContent from "./modal/ModalFifteenContent";
import ModalSixteenContent from "./modal/ModalSixteenContent";
import ModalSeventeenContent from "./modal/ModalSeventeenContent";
import ModalEighteenContent from "./modal/ModalEighteenContent";
import ModalNineteenContent from "./modal/ModalNineteenContent";
import ModalTwentyContent from "./modal/ModalTwentyContent";
import ModalTwentyoneContent from "./modal/ModalTwentyoneContent";
import ModalTwentytwoContent from "./modal/ModalTwentytwoContent";

const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);
  const [isOpen18, setIsOpen18] = useState(false);
  const [isOpen19, setIsOpen19] = useState(false);
  const [isOpen20, setIsOpen20] = useState(false);
  const [isOpen21, setIsOpen21] = useState(false);
  const [isOpen22, setIsOpen22] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }
  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }
  function toggleModalEight() {
    setIsOpen8(!isOpen8);
  }
  function toggleModalNine() {
    setIsOpen9(!isOpen9);
  }
  function toggleModalTen() {
    setIsOpen10(!isOpen10);
  }
  function toggleModalEleven() {
    setIsOpen11(!isOpen11);
  }
  function toggleModalTwelve() {
    setIsOpen12(!isOpen12);
  }
  function toggleModalThirteen() {
    setIsOpen13(!isOpen13);
  }
  function toggleModalFourteen() {
    setIsOpen14(!isOpen14);
  }
  function toggleModalFifteen() {
    setIsOpen15(!isOpen15);
  }
  function toggleModalSixteen() {
    setIsOpen16(!isOpen16);
  }
  function toggleModalSeventeen(){
    setIsOpen17(!isOpen17);
  }  
  function toggleModalEighteen(){
    setIsOpen18(!isOpen18);
  }  
  function toggleModalNineteen(){
    setIsOpen19(!isOpen19);
  }
  function toggleModalTwenty(){
    setIsOpen20(!isOpen20);
  }
  function toggleModalTwentyone(){
    setIsOpen21(!isOpen21);
  }
  function toggleModalTwentytwo(){
    setIsOpen22(!isOpen22);
  }

  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>ALL</Tab>
        <Tab>Projects</Tab>
        <Tab>Certificate</Tab>
        <Tab>Competitions</Tab>
      </TabList>

      <div className="portfolio-tab-content">
        <TabPanel>
          <ul className="row grid justify-content-center">
             {/* <!-- 1FIRE Item Ends --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/projects/project-1.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">FIRE: Design Innovations</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalOneContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- 1FIRE Item Ends --> */}

            {/* <!-- 2FIRE Item Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/projects/project-2.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> FIRE: Global Design Development</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModalTwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- 2FIRE Item Ends --> */}

            {/* <!-- 3Baja Item Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/projects/project-3.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">SAE Baja</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                isOpen={isOpen3}
                onRequestClose={toggleModalThree}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalThreeContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalThreeContent */}
            </li>
            {/* <!-- 3Baja Item Ends --> */}

            {/* <!-- 4FBLA Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFour}
              >
                <img src="img/projects/project-4.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">FBLA: Network Design</span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                isOpen={isOpen4}
                onRequestClose={toggleModalFour}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFourContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>
            {/* <!-- 4FBLA Item Ends --> */}

            {/* <!-- 5FBLA Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFive}
              >
                <img src="img/projects/project-5.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">FBLA: Problem Solving</span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen5}
                onRequestClose={toggleModalFive}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFive}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFiveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- 5FBLA Item Ends --> */}

            {/* <!-- 6SAE Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSix}
              >
                <img src="img/projects/project-6.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">SAE Cyber Auto Challenge</span>
                </div>
              </figure>

              {/* Start ModalSixContent */}
              <Modal
                isOpen={isOpen6}
                onRequestClose={toggleModalSix}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSix}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSixContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSixContent */}
            </li>
            {/* <!-- 6SAE Item Ends --> */}

            {/* <!-- 7NCL Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSeven}
              >
                <img src="img/projects/project-7.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> National Cyber League</span>
                </div>
              </figure>

              {/* Start ModalSevenContent */}
              <Modal
                isOpen={isOpen7}
                onRequestClose={toggleModalSeven}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSeven}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSevenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSevenContent */}
            </li>
            {/* <!-- 7NCL Item Ends --> */}

            {/* <!-- 8Metropolis Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEight}
              >
                <img src="img/projects/project-8.jpg" alt="Portolio" />

                <div className=" hover-content-wrapper">
                  <span className="content-title"> Metropolis</span>
                </div>
              </figure>
              {/* Start ModalEightContent */}
              <Modal
                isOpen={isOpen8}
                onRequestClose={toggleModalEight}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEight}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalEightContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalEightContent */}
            </li>
            {/* <!-- 8Metropolis Item Ends --> */}

            {/* <!-- 9Qwiklabs Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalNine}
              >
                <img src="img/projects/project-9.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Qwiklabs</span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen9}
                onRequestClose={toggleModalNine}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalNine}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalNineContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- 9Qwiklabs Item Ends --> */}

            {/* <!-- 10hackumbc Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTen}
              >
                <img src="img/projects/project-10.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">HackUMBC</span>
                </div>
              </figure>

              {/* Start ModalTenContent */}
              <Modal
                isOpen={isOpen10}
                onRequestClose={toggleModalTen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTenContent */}
            </li>
            {/* <!-- 10hackumbc Item Ends --> */}

            {/* <!-- 11MagicCTF Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEleven}
              >
                <img src="img/projects/project-11.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Magic CTF</span>
                </div>
              </figure>

              {/* Start ModalElevenContent */}
              <Modal
                isOpen={isOpen11}
                onRequestClose={toggleModalEleven}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEleven}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalElevenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalElevenContent */}
            </li>
            {/* <!-- 11MagicCTF Item Ends --> */}

            {/* <!-- 12Yhack Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwelve}
              >
                <img src="img/projects/project-12.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Yhack</span>
                </div>
              </figure>

              {/* Start ModalTwelveContent */}
              <Modal
                isOpen={isOpen12}
                onRequestClose={toggleModalTwelve}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwelve}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwelveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 12Yhack Item Ends --> */}

            {/* <!-- 13Hack The Box Item Starts --> */}
             <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalThirteen}
              >
                <img src="img/projects/project-13.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Hack The Box</span>
                </div>
              </figure>

              {/* Start ModalThirteenContent */}
              <Modal
                isOpen={isOpen13}
                onRequestClose={toggleModalThirteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThirteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalThirteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalThirteenContent */}
            </li>
            {/* <!-- 13Hack The Box Item Ends --> */}

            {/* <!-- 14PicoCTF Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFourteen}
              >
                <img src="img/projects/project-14.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">PicoCTF</span>
                </div>
              </figure>

              {/* Start ModalFourteenContent */}
              <Modal
                isOpen={isOpen14}
                onRequestClose={toggleModalFourteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFourteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFourteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFourteenContent */}
            </li>
            {/* <!-- 14PicoCTF Item Ends --> */}

            {/* <!-- 15VolgaCTF Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFifteen}
              >
                <img src="img/projects/project-15.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">VolgaCTF</span>
                </div>
              </figure>

              {/* Start ModalFifteenContent */}
              <Modal
                isOpen={isOpen15}
                onRequestClose={toggleModalFifteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFifteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFifteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 15VolgaCTF Item Ends --> */}

            {/* <!-- 16Bitcamp Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSixteen}
              >
                <img src="img/projects/project-16.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Bitcamp</span>
                </div>
              </figure>

              {/* Start ModalSixteenContent */}
              <Modal
                isOpen={isOpen16}
                onRequestClose={toggleModalSixteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSixteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSixteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 16Bitcamp Item Ends --> */}

            {/* <!-- 17Sololearn Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSeventeen}
              >
                <img src="img/projects/project-17.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Sololearn</span>
                </div>
              </figure>

              {/* Start ModalSeventeenContent */}
              <Modal
                isOpen={isOpen17}
                onRequestClose={toggleModalSeventeen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSeventeen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSeventeenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSeventeenContent */}
            </li>
            {/* <!-- 17Sololearn Item Ends --> */}

            {/* <!-- 18CyberPatriot Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEighteen}
              >
                <img src="img/projects/project-18.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">CyberPatriot</span>
                </div>
              </figure>

              {/* Start ModalEighteenContent */}
              <Modal
                isOpen={isOpen18}
                onRequestClose={toggleModalEighteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEighteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalEighteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalEighteenContent */}
            </li>
            {/* <!-- 18CyberPatriot Item Ends --> */}

            {/* <!-- 19AT&T Summer Learning Academy Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalNineteen}
              >
                <img src="img/projects/project-19.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">AT&T Summer Learning Academy</span>
                </div>
              </figure>

              {/* Start ModalNineteenContent */}
              <Modal
                isOpen={isOpen19}
                onRequestClose={toggleModalNineteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalNineteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalNineteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 19AT&T Summer Learning Academy Item Ends --> */}

            {/* <!-- 20BabyMonstah Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwenty}
              >
                <img src="img/projects/project-20.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">BabyMonstah Academy</span>
                </div>
              </figure>

              {/* Start ModalTwentyContent */}
              <Modal
                isOpen={isOpen20}
                onRequestClose={toggleModalTwenty}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwenty}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwentyContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 20BabyMonstah Item Ends --> */}

            {/* <!-- 21Primal Gaea Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwentyone}
              >
                <img src="img/projects/project-21.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Primal Gaea</span>
                </div>
              </figure>

              {/* Start ModalTwentyoneContent */}
              <Modal
                isOpen={isOpen21}
                onRequestClose={toggleModalTwentyone}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwentyone}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwentyoneContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 21Primal Gaea Item Ends --> */}

                        {/* <!-- 22 INST380 Item Starts --> */}
                        <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwentytwo}
              >
                <img src="img/projects/Project-21/challengebg.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">INST 380</span>
                </div>
              </figure>

              {/* Start ModalTwentyoneContent */}
              <Modal
                isOpen={isOpen22}
                onRequestClose={toggleModalTwentytwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwentytwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwentytwoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 22 INST380 Item Starts --> */}


          </ul>
        </TabPanel>
        {/* End All Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
           {/* <!-- 1FIRE Item Ends --> */}
          <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/projects/project-1.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">FIRE: Design Innovations</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalOneContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- 1FIRE Item Ends --> */}

            {/* <!-- 2FIRE Item Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/projects/project-2.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> FIRE: Global Design Development</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModalTwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- 2FIRE Item Ends --> */}

            {/* <!-- 3Baja Item Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/projects/project-3.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">SAE Baja</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                isOpen={isOpen3}
                onRequestClose={toggleModalThree}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalThreeContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalThreeContent */}
            </li>
            {/* <!-- 3Baja Item Ends --> */}
          
            {/* <!-- 20BabyMonstah Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwenty}
              >
                <img src="img/projects/project-20.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">BabyMonstah Academy</span>
                </div>
              </figure>

              {/* Start ModalTwentyContent */}
              <Modal
                isOpen={isOpen20}
                onRequestClose={toggleModalTwenty}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwenty}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwentyContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 20BabyMonstah Item Ends --> */}

            {/* <!-- 21Primal Gaea Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwentyone}
              >
                <img src="img/projects/project-21.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Primal Gaea</span>
                </div>
              </figure>

              {/* Start ModalTwentyoneContent */}
              <Modal
                isOpen={isOpen21}
                onRequestClose={toggleModalTwentyone}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwentyone}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwentyoneContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 21Primal Gaea Item Ends --> */}

            {/* <!-- 22 INST380 Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwentytwo}
              >
                <img src="img/projects/Project-21/challengebg.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">INST 380</span>
                </div>
              </figure>

              {/* Start ModalTwentyoneContent */}
              <Modal
                isOpen={isOpen22}
                onRequestClose={toggleModalTwentytwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwentytwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwentytwoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 22 INST380 Item Starts --> */}

          </ul>
        </TabPanel>
        {/* End Projects Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
            
            {/* <!-- 9Qwiklabs Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalNine}
              >
                <img src="img/projects/project-9.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Qwiklabs</span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen9}
                onRequestClose={toggleModalNine}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalNine}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalNineContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- 9Qwiklabs Item Ends --> */}

            {/* <!-- 17Sololearn Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSeventeen}
              >
                <img src="img/projects/project-17.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Sololearn</span>
                </div>
              </figure>

              {/* Start ModalSeventeenContent */}
              <Modal
                isOpen={isOpen17}
                onRequestClose={toggleModalSeventeen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSeventeen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSeventeenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSeventeenContent */}
            </li>
            {/* <!-- 17Sololearn Item Ends --> */}

            {/* <!-- 19AT&T Summer Learning Academy Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalNineteen}
              >
                <img src="img/projects/project-19.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">AT&T Summer Learning Academy</span>
                </div>
              </figure>

              {/* Start ModalNineteenContent */}
              <Modal
                isOpen={isOpen19}
                onRequestClose={toggleModalNineteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalNineteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalNineteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 19AT&T Summer Learning Academy Item Ends --> */}
          </ul>
        </TabPanel>
        {/* End Award Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
            {/* <!-- Portfolio Item Starts --> */}
                      {/* <!-- 4FBLA Item Starts --> */}
                      <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFour}
              >
                <img src="img/projects/project-4.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">FBLA: Network Design</span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                isOpen={isOpen4}
                onRequestClose={toggleModalFour}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFourContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>
            {/* <!-- 4FBLA Item Ends --> */}

            {/* <!-- 5FBLA Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFive}
              >
                <img src="img/projects/project-5.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">FBLA: Problem Solving</span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen5}
                onRequestClose={toggleModalFive}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFive}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFiveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- 5FBLA Item Ends --> */}

            {/* <!-- 7NCL Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSeven}
              >
                <img src="img/projects/project-7.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> National Cyber League</span>
                </div>
              </figure>

              {/* Start ModalSevenContent */}
              <Modal
                isOpen={isOpen7}
                onRequestClose={toggleModalSeven}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSeven}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSevenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSevenContent */}
            </li>
            {/* <!-- 7NCL Item Ends --> */}

            {/* <!-- 8Metropolis Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEight}
              >
                <img src="img/projects/project-8.jpg" alt="Portolio" />

                <div className=" hover-content-wrapper">
                  <span className="content-title"> Metropolis</span>
                </div>
              </figure>
              {/* Start ModalEightContent */}
              <Modal
                isOpen={isOpen8}
                onRequestClose={toggleModalEight}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEight}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalEightContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalEightContent */}
            </li>
            {/* <!-- 8Metropolis Item Ends --> */}

            {/* <!-- 10hackumbc Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTen}
              >
                <img src="img/projects/project-10.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">HackUMBC</span>
                </div>
              </figure>

              {/* Start ModalTenContent */}
              <Modal
                isOpen={isOpen10}
                onRequestClose={toggleModalTen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTenContent */}
            </li>
            {/* <!-- 10hackumbc Item Ends --> */}

            {/* <!-- 11MagicCTF Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEleven}
              >
                <img src="img/projects/project-11.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Magic CTF</span>
                </div>
              </figure>

              {/* Start ModalElevenContent */}
              <Modal
                isOpen={isOpen11}
                onRequestClose={toggleModalEleven}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEleven}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalElevenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalElevenContent */}
            </li>
            {/* <!-- 11MagicCTF Item Ends --> */}

            {/* <!-- 12Yhack Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalTwelve}
              >
                <img src="img/projects/project-12.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Yhack</span>
                </div>
              </figure>

              {/* Start ModalTwelveContent */}
              <Modal
                isOpen={isOpen12}
                onRequestClose={toggleModalTwelve}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwelve}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwelveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 12Yhack Item Ends --> */}

            {/* <!-- 13Hack The Box Item Starts --> */}
             <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalThirteen}
              >
                <img src="img/projects/project-13.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Hack The Box</span>
                </div>
              </figure>

              {/* Start ModalThirteenContent */}
              <Modal
                isOpen={isOpen13}
                onRequestClose={toggleModalThirteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThirteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalThirteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalThirteenContent */}
            </li>
            {/* <!-- 13Hack The Box Item Ends --> */}

            {/* <!-- 14PicoCTF Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFourteen}
              >
                <img src="img/projects/project-14.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">PicoCTF</span>
                </div>
              </figure>

              {/* Start ModalFourteenContent */}
              <Modal
                isOpen={isOpen14}
                onRequestClose={toggleModalFourteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFourteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFourteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFourteenContent */}
            </li>
            {/* <!-- 14PicoCTF Item Ends --> */}

            {/* <!-- 15VolgaCTF Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFifteen}
              >
                <img src="img/projects/project-15.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">VolgaCTF</span>
                </div>
              </figure>

              {/* Start ModalFifteenContent */}
              <Modal
                isOpen={isOpen15}
                onRequestClose={toggleModalFifteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFifteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFifteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 15VolgaCTF Item Ends --> */}

            {/* <!-- 16Bitcamp Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSixteen}
              >
                <img src="img/projects/project-16.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Bitcamp</span>
                </div>
              </figure>

              {/* Start ModalSixteenContent */}
              <Modal
                isOpen={isOpen16}
                onRequestClose={toggleModalSixteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSixteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSixteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwelveContent */}
            </li>
            {/* <!-- 16Bitcamp Item Ends --> */}

            {/* <!-- 17Sololearn Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSeventeen}
              >
                <img src="img/projects/project-17.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Sololearn</span>
                </div>
              </figure>

              {/* Start ModalSeventeenContent */}
              <Modal
                isOpen={isOpen17}
                onRequestClose={toggleModalSeventeen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSeventeen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSeventeenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSeventeenContent */}
            </li>
            {/* <!-- 17Sololearn Item Ends --> */}



          </ul>
        </TabPanel>
        {/* End Competition Project*/}


      </div>
    </Tabs>
  );
};

export default Portfolio;
