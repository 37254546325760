import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>

        <figcaption>
          <h3>Metropolis</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">team-based game that focuses on introducing students to cybersecurity.
Players will solve a series of cybersecurity challenges based on real-world problems. Get a chance to track down hackers and break into websites in a safe environment.</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Ranking </span>:{" "}
              <br></br>
              <span className="ft-wt-600 uppercase">Round 1</span> Top 50 teams
              <br></br>
              <span className="ft-wt-600 uppercase">Round 2</span> Top 30 teams 
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Skills </span>:{" "}
              <span className="ft-wt-600 uppercase">
              <ul>
                  <li>Open Source Intelligent</li>  
                  <li>Password Cracking</li>  
                  <li>Network Analysis</li>  
                  <li>Live Exploitation</li>  
                  <li>Cryptography</li>  
                  <li>Log Analysis</li>  
                  <li>Reverse Engineering</li>  
                  <li>Forensics</li>
                </ul>
              </span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://cyberskyline.com/hosted_events/metropolis-2018"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
          </div>
        </figcaption>

      </figure>
    </div>
  );
};

export default ModalEightContent;
