import React from "react";

const ModalEightContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Sololearn</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Summary </span>:{" "}
              <br></br>
              <span className="ft-wt-60scase">SoloLearn is a free programming and coding education app designed to teach the basics of software development. Normally a time-consuming process, SoloLearn makes it quicker and simpler by breaking down programming concepts into easy, digestible, daily lessons.</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Certification </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <br></br>C# Tutorial
                <br></br>CSS Fundamentals
                <br></br>JavaScript Tutorial
                <br></br>SQL Fundamentals
                <br></br>Java Tutorial
                <br></br>HTML Fundamentals
                

              </span>
            </div>


            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://www.sololearn.com/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalEightContent;
